import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import router from 'next/router';
import { Role as NavRole } from '@/components/dynamic-nav/types';
import { useNavContext } from '@/providers/NavProvider/navContextProvider';
import { useSettings } from '@/providers/SettingsProvider/SettingsProvider';
import { useStore } from '@/store/authorityType';
import { Role } from '@/types/user';
import { Routes } from '@/utils/urls';
import { LocationCityOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Stack, SxProps, Typography } from '@mui/material';

type Props = { sx?: SxProps };
export const ImpersonationAlert = ({ sx }: Props) => {
  const { data: session, update } = useSession();
  const { setRole } = useSettings();
  const { t } = useTranslation('common', { keyPrefix: 'common.impersonationAlert' });
  const { isOpen } = useNavContext();
  const { resetAuthorityTypeId } = useStore();

  if (!session?.user.isImpersonation) return null;

  const { orgName } = session;
  const handleOnClick = async () => {
    try {
      await update({
        ...session,
        orgId: '',
        orgName: '',
        isImpersonation: false,
        roles: [
          Role.CDP_ADMIN,
        ],
      });

      resetAuthorityTypeId();

      setRole(NavRole.CDP_ADMIN);

      await router.push({
        pathname: Routes.Admin.Root,
      });
      router.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return isOpen ? (
    <Alert
      icon={<LocationCityOutlined />}
      variant="filled"
      severity="info"
      sx={{
        color: theme => theme.palette.common.white,
        borderRadius: '4px !important',
        ...sx,
      }}
    >
      <AlertTitle>{t('title', { orgName })}</AlertTitle>
      <Typography mb={2}>{t('description', { orgName })}</Typography>
      <Stack>
        <Button
          variant="outlined"
          color="inherit"
          onClick={handleOnClick}
        >
          {t('ctaLabel')}
        </Button>
      </Stack>
    </Alert>
  ) : (
    <Button
      color="info"
      variant="contained"
      onClick={handleOnClick}
      sx={{
        borderRadius: '4px',
        ...sx,
      }}
    >
      <LocationCityOutlined sx={{ color: 'white' }} />
    </Button>
  );
};
