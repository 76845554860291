// mui
import { Box, Divider, List } from '@mui/material';
//components
import { NavListItem } from './NavListItem';
//types
import { NavLinkDivider, NavLinkItem, NotificationType } from './types';

interface NavListProps {
  list: (NavLinkItem | NavLinkDivider)[];
  requestCount?: number;
}

export const NavList = ({ list, requestCount }: NavListProps) => {
  if (!list) return null;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <List
        sx={{
          px: 0,
          py: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {list.map(navItem => {
          if (navItem.type === 'divider') {
            return (
              <Divider
                key="divider"
                sx={{
                  alignSelf: 'stretch',
                  borderTop: 'solid secondary.main 1px',
                  margin: '16px 0',
                }}
              />
            );
          }

          const { title, path, icon, notificationType, ...rest } = navItem;

          return (
            <NavListItem
              key={`${title}-${path}`}
              path={path}
              title={title}
              icon={icon}
              {...(notificationType === NotificationType.REQUEST && {
                requestCount,
              })}
              {...rest}
            />
          );
        })}
      </List>
    </Box>
  );
};
