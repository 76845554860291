export class OrganisationTypeIdService {
  static STATES_AND_REGIONS() {
    return process.env.NEXT_PUBLIC_SUB_GOVERNMENT_ORGANISATION_TYPE_ID ?? 'fac2e5f5-802a-e711-a94c-0050569976b2';
  }

  static COMPANY() {
    return process.env.NEXT_PUBLIC_COMPANY_ORGANISATION_TYPE_ID ?? 'ecc2e5f5-802a-e711-a94c-0050569976b2';
  }

  static CITY() {
    return process.env.NEXT_PUBLIC_CITY_ORGANISATION_TYPE_ID ?? 'eec2e5f5-802a-e711-a94c-0050569976b2';
  }

  static OTHER() {
    return process.env.NEXT_PUBLIC_OTHER_ORGANISATION_TYPE_ID ?? 'b548aec9-4baf-e711-816b-000d3a276ebc';
  }

  static NATIONAL_GOVERNMENT() {
    return process.env.NEXT_PUBLIC_NATIONAL_GOVERNMENT_ORGANISATION_TYPE_ID ?? 'f8c2e5f5-802a-e711-a94c-0050569976b2';
  }
}
