// mui
// simplebar-react
import SimpleBar, { Props } from 'simplebar-react';
// scroll bar css file
import 'simplebar-react/dist/simplebar.min.css';
import { Box, SxProps, Theme, alpha, styled } from '@mui/material';

export interface ScrollbarProps extends Props {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  isMobile?: boolean;
}

export const StyledScrollbar = styled(SimpleBar)(({ theme }) => ({
  maxHeight: '100%',
  maxWidth: '100%',
  minWidth: 0,
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[500], 0.5),
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));

export default function Scrollbar({ isMobile, children, sx, ...other }: ScrollbarProps) {
  if (isMobile) {
    return (
      <Box
        sx={{ overflowX: 'auto', ...sx }}
        {...other}
      >
        {children}
      </Box>
    );
  }

  return (
    <StyledScrollbar
      clickOnTrack={false}
      sx={sx}
      {...other}
    >
      {children}
    </StyledScrollbar>
  );
}
