import { useRouter } from 'next/router';
import { REMOVE_A_DISCLOSER } from '@/graphql/disclosure/mutations';
import { GET_USER_TASKS } from '@/graphql/disclosure/queries';
import { useStore } from '@/store/authorityType';
import { disclosureClient } from '@/utils/apollo-client';
import { Routes } from '@/utils/urls';
import { useMutation } from '@apollo/client';
import { ListItemButton, ListItemText, Stack } from '@mui/material';

type Props = {
  isProd: boolean;
};
export const ResetButton = ({ isProd }: Props) => {
  const { authorityTypeId } = useStore();
  const router = useRouter();

  const [
    removeDiscloserList,
    { loading },
  ] = useMutation(REMOVE_A_DISCLOSER, {
    client: disclosureClient,
    variables: {
      authorityTypeId,
    },
    refetchQueries: [
      {
        query: GET_USER_TASKS,
        variables: {
          authorityTypeId,
        },
      },
    ],
  });

  const handleReset = async () => {
    try {
      await removeDiscloserList();
      router.push(Routes.Home);
    } catch (err) {
      console.log(err);
    }
  };

  if (isProd) return null;
  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <ListItemButton
        disabled={loading}
        onClick={handleReset}
      >
        <ListItemText primary={loading ? 'Loading' : 'Reset'} />
      </ListItemButton>
    </Stack>
  );
};
