import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { createAuthorityTypeSlice } from './authorityTypeSlice';
import { AuthorityTypeState } from './authorityTypes';

const useAuthorityTypeStore = create<AuthorityTypeState>()(
  devtools(persist(createAuthorityTypeSlice, { name: 'authority-type' })),
);

export const useStore = () => useAuthorityTypeStore(state => state);
