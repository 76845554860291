import { ResourceKey } from 'i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubLinks } from '@/components/dynamic-nav/SubLinks';
import { NavLinkItem } from '@/components/dynamic-nav/types';
import useActiveRoute from '@/hooks/useActiveRoute';
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@mui/icons-material';
import {
  Badge,
  ButtonProps,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Theme,
  alpha,
  styled,
} from '@mui/material';
import { useNavContext } from '../../providers/NavProvider/navContextProvider';
import { LIST_ITEM_ICON_OFFSET } from '../nav/nav-config';

const itemTypography = {
  fontSize: '14px',
  lineHeight: '22px',
  fontWeight: (theme: Theme) => theme.typography.fontWeightMedium,
};

interface NavListItemProps {
  path: string;
  title: ResourceKey;
  icon?: JSX.Element;
  requestCount?: number;
  subs?: NavLinkItem[];
  isExternalLink?: boolean;
}

export const NavListItem = ({ path, title, icon, requestCount, subs, isExternalLink }: NavListItemProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'common' });
  const router = useRouter();
  const { isOpen } = useNavContext();
  const { active } = useActiveRoute(path, false);
  const [
    isSubsShown,
    setIsSubsShown,
  ] = useState(false);
  const handleOnClick = () => {
    if (subs && subs?.length > 0) {
      setIsSubsShown(!isSubsShown);
    } else if (isExternalLink) {
      window.open(path, '_blank');
    } else {
      router.push(path);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setIsSubsShown(false);
    }
  }, [
    isOpen,
  ]);

  return (
    <StyledItemButton
      data-testid="nav-list-item"
      active={active}
      onClick={handleOnClick}
      sx={{
        position: 'relative',
        width: '100%',
      }}
      className={isSubsShown ? 'with-subs-opened' : ''}
    >
      {isSubsShown && subs && subs?.length > 0 && <SubLinks subs={subs} />}

      <ListItemIcon
        sx={{
          paddingLeft: `${LIST_ITEM_ICON_OFFSET}px`,
          color: theme => (active ? theme.palette.primary.main : theme.palette.secondary.main),
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        title={t(title)}
        primary={t(title)}
        primaryTypographyProps={{
          noWrap: true,
          sx: {
            ...itemTypography,
            color: theme => (active ? theme.palette.primary.main : theme.palette.secondary.main),
          },
        }}
      />

      {!!requestCount && (
        <Badge
          data-testid="request-count"
          aria-label={t('nav.notificationsAria', { notifications: requestCount })}
          badgeContent={requestCount}
          color="primary"
        />
      )}

      {subs &&
        subs?.length > 0 &&
        isOpen &&
        (isSubsShown ? <ArrowDropUpOutlined fontSize="medium" /> : <ArrowDropDownOutlined fontSize="medium" />)}
    </StyledItemButton>
  );
};
interface NavListButtonProps {
  title: string;
  icon?: JSX.Element;
}
export const NavListButton = ({ title, icon, ...muiProps }: NavListButtonProps & ListItemButtonProps & ButtonProps) => {
  return (
    <StyledItemButton {...muiProps}>
      <ListItemIcon
        sx={{
          paddingLeft: `${LIST_ITEM_ICON_OFFSET}px`,
          color: theme => theme.palette.secondary.main,
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        title={title}
        primary={title}
        primaryTypographyProps={{
          noWrap: true,
          sx: {
            ...itemTypography,
          },
        }}
      />
    </StyledItemButton>
  );
};

type StyledItemProps = {
  active?: boolean;
} & ListItemButtonProps;

export const StyledItemButton = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== 'active',
})<StyledItemProps>(({ active, theme }) => {
  const activeStyle = {
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.main, 0.04),
  };

  const hoverStyle = {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  };

  return {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    '&:hover': hoverStyle,
    '&.with-subs-opened': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04),
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.04),
      },
    },
    // Active item
    ...(active && {
      ...activeStyle,
      '&:hover': {
        ...activeStyle,
      },
    }),
  };
});
