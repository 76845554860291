import { GetServerSideProps } from 'next';
import { signIn } from 'next-auth/react';
import { FunctionComponent, useEffect } from 'react';

interface Props {
  callbackUrl: string;
}

const SignIn: FunctionComponent<Props> = ({ callbackUrl = '/' }) => {
  useEffect(() => {
    signIn('azure-ad-b2c', { callbackUrl });
  }, []);

  return null;
};

export default SignIn;

export const getServerSideProps: GetServerSideProps = async () => {
  return { props: {} };
};
