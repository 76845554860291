import { FunctionComponent, ReactNode } from 'react';
import { DynamicNav } from '@/components/dynamic-nav/DynamicNav';
import { Box } from '@mui/material';

interface Props {
  children?: ReactNode;
}

const MainNav: FunctionComponent<Props> = ({ children }) => {
  return (
    <Box>
      <DynamicNav />
      {children}
    </Box>
  );
};

export default MainNav;
