// types
// utils
import { Routes } from '@/utils/urls';
import { commonConfig } from '@config';
// icons
import {
  ContactMailOutlined,
  CorporateFareOutlined,
  DescriptionOutlined,
  FeedbackOutlined,
  HelpOutlineOutlined,
  HomeOutlined,
  MapsUgcOutlined,
  SettingsOutlined,
} from '@mui/icons-material';
import { DynamicNavDataType, NotificationType, Role } from './types';

export const NAV_WIDTH = 275;

const { Account, Authority, Home, MyRequests, Questionnaire, SupportResources } = Routes;

export const getDynamicNavData = (role: Role): DynamicNavDataType => {
  switch (role) {
    case Role.DISCLOSER: {
      return {
        role: Role.DISCLOSER,
        links: [
          {
            type: 'item',
            title: 'nav.listItems.homePage',
            path: Home,
            icon: <HomeOutlined />,
          },
          {
            type: 'item',
            title: 'nav.listItems.myQuestionnaire',
            path: Questionnaire.Root,
            icon: <DescriptionOutlined />,
          },
          {
            type: 'item',
            title: 'nav.listItems.myRequests',
            path: MyRequests,
            icon: <MapsUgcOutlined />,
            notificationType: NotificationType.REQUEST,
          },
          {
            type: 'divider',
          },
        ],
        footer: [
          {
            type: 'item',
            title: 'nav.footer.supportResources',
            path: SupportResources.root,
            icon: <HelpOutlineOutlined />,
            subs: [
              {
                type: 'item',
                title: 'nav.footerResourcesSubs.helpCentre',
                path: commonConfig.helpCentreLink,
                isExternalLink: true,
              },
              {
                type: 'item',
                title: 'nav.footerResourcesSubs.reportingGuidance',
                path: commonConfig.reportingGuidanceLink,
                isExternalLink: true,
              },
              {
                type: 'item',
                title: 'nav.footerResourcesSubs.additionalGuidance',
                path: commonConfig.additionalGuidanceLink,
                isExternalLink: true,
              },
              {
                type: 'item',
                title: 'nav.footerResourcesSubs.getInTouch',
                path: commonConfig.getInTouchLink,
                isExternalLink: true,
              },
            ],
          },
          {
            type: 'item',
            title: 'nav.footer.feedback',
            path: commonConfig.feedbackTypeformLink,
            icon: <FeedbackOutlined />,
            isExternalLink: true,
          },
          {
            type: 'item',
            title: 'nav.footer.settings',
            path: Account,
            icon: <SettingsOutlined />,
          },
        ],
      };
    }
    case Role.AUTHORITY: {
      return {
        role: Role.AUTHORITY,
        links: [
          {
            type: 'item',
            title: 'nav.listItems.homePage',
            path: Home,
            icon: <HomeOutlined />,
          },
          {
            type: 'item',
            title: 'nav.listItems.manageOrgs',
            path: Authority.Manage,
            icon: <CorporateFareOutlined />,
          },
          {
            type: 'item',
            title: 'nav.listItems.engageOrgs',
            path: Authority.Root,
            icon: <ContactMailOutlined />,
          },
          {
            type: 'divider',
          },
        ],
        footer: [
          {
            type: 'item',
            title: 'nav.footer.supportResources',
            path: SupportResources.root,
            icon: <HelpOutlineOutlined />,
            subs: [
              {
                type: 'item',
                title: 'nav.footerResourcesSubs.helpCentre',
                path: commonConfig.helpCentreLink,
                isExternalLink: true,
              },
              {
                type: 'item',
                title: 'nav.footerResourcesSubs.reportingGuidance',
                path: commonConfig.reportingGuidanceLink,
                isExternalLink: true,
              },
              {
                type: 'item',
                title: 'nav.footerResourcesSubs.additionalGuidance',
                path: commonConfig.additionalGuidanceLink,
                isExternalLink: true,
              },
              {
                type: 'item',
                title: 'nav.footerResourcesSubs.getInTouch',
                path: commonConfig.getInTouchLink,
                isExternalLink: true,
              },
            ],
          },
          {
            type: 'item',
            title: 'nav.footer.feedback',
            path: commonConfig.feedbackTypeformLink,
            icon: <FeedbackOutlined />,
            isExternalLink: true,
          },
          {
            type: 'item',
            title: 'nav.footer.settings',
            path: Account,
            icon: <SettingsOutlined />,
          },
        ],
      };
    }
    case Role.CDP_ADMIN: {
      return {
        role: Role.CDP_ADMIN,
        links: [
          {
            type: 'item',
            title: 'nav.listItems.homePage',
            path: Home,
            icon: <HomeOutlined />,
          },
          {
            type: 'divider',
          },
        ],
        footer: [
          {
            type: 'item',
            title: 'nav.footer.supportResources',
            path: SupportResources.root,
            icon: <HelpOutlineOutlined />,
            subs: [
              {
                type: 'item',
                title: 'nav.footerResourcesSubs.helpCentre',
                path: commonConfig.helpCentreLink,
                isExternalLink: true,
              },
              {
                type: 'item',
                title: 'nav.footerResourcesSubs.additionalGuidance',
                path: commonConfig.additionalGuidanceLink,
                isExternalLink: true,
              },
              {
                type: 'item',
                title: 'nav.footerResourcesSubs.getInTouch',
                path: commonConfig.getInTouchLink,
                isExternalLink: true,
              },
            ],
          },
          {
            type: 'item',
            title: 'nav.footer.feedback',
            path: commonConfig.feedbackTypeformLink,
            icon: <FeedbackOutlined />,
            isExternalLink: true,
          },
          {
            type: 'item',
            title: 'nav.footer.settings',
            path: Account,
            icon: <SettingsOutlined />,
          },
        ],
      };
    }
    case Role.UNKNOWN:
    default: {
      return {
        role: Role.UNKNOWN,
        links: [],
        footer: [],
      };
    }
  }
};
