import { MenuProps } from '@mui/material/Menu';

// Making open prop optional. We want to handle opening and closing the Select in the component itself.
// This interface avoids setting a non mutable open state for the Select and allows it to keep its dynamic nature.
interface CustomMenuProps extends Omit<MenuProps, 'open'> {
  open?: boolean;
}

export const selectMenuProps: CustomMenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  PaperProps: {
    style: {
      maxHeight: 200,
      maxWidth: '90%',
      width: 'auto',
    },
  },
};

export const textFieldSelectProps = {
  MenuProps: selectMenuProps,
};
