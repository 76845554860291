import { StateCreator } from 'zustand';
import { AuthorityTypeState } from './authorityTypes';

export const createAuthorityTypeSlice: StateCreator<AuthorityTypeState> = set => ({
  authorityTypeId: '',
  setAuthorityTypeId: (authorityTypeId: string) =>
    set(() => ({
      authorityTypeId,
    })),
  resetAuthorityTypeId: () => set(() => ({ authorityTypeId: '' })),
});
