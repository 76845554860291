// mui
import { List } from '@mui/material';
// components
import { NavListItem } from './NavListItem';
// types
import { NavLinkItem } from './types';

interface FooterProps {
  list: NavLinkItem[];
}

export const Footer = ({ list }: FooterProps) => {
  return (
    <List>
      {list.map(footerItem => {
        const { title, path, icon, subs, ...rest } = footerItem;

        return (
          <NavListItem
            key={`${title}-${path}`}
            path={path}
            title={title}
            icon={icon}
            subs={subs}
            {...rest}
          />
        );
      })}
    </List>
  );
};
