// react
import { PropsWithChildren } from 'react';
// context
import AuthGuard from '@/auth/AuthGuard';
// mui
import { BoxProps } from '@mui/material';
// components
import { Layout } from './layout';

type Props = PropsWithChildren & BoxProps;

export default function MainLayout({ children, sx = { p: 4 } }: Props) {
  return (
    <AuthGuard>
      <Layout sx={sx}>{children}</Layout>
    </AuthGuard>
  );
}
