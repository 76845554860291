import { PropsWithChildren, createContext, useContext, useState } from 'react';

type INavContext = {
  isOpen: boolean;
  openNav: VoidFunction;
  closeNav: VoidFunction;
};

const defaultContext = {
  isOpen: false,
  openNav: () => null,
  closeNav: () => null,
};

const NavContext = createContext<INavContext>(defaultContext);

export const NavContextProvider = ({ children }: PropsWithChildren) => {
  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(true);

  const closeNav = () => setIsOpen(false);
  const openNav = () => setIsOpen(true);

  return (
    <NavContext.Provider
      value={{
        isOpen,
        closeNav,
        openNav,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export const useNavContext = () => {
  return useContext(NavContext);
};
