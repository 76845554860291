// utils
import axios from 'axios';
// 3rd
import jwt from 'jsonwebtoken';
// next auth
import { getSession } from 'next-auth/react';
import { updateToken } from '@/utils/apollo-client';

let expiredTimer: string | number | NodeJS.Timeout | undefined;

export const decodeJWT = (token: string): Record<string, any> | undefined => {
  try {
    const decodedToken = jwt.decode(token);
    return decodedToken as Record<string, any>;
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return undefined;
  }
};

export const isTokenValid = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = decodeJWT(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded?.exp > currentTime;
};

export const tokenExpired = (exp: number) => {
  const currentTime = Date.now();
  const expirationTime = exp * 1000;
  const timeLeft = expirationTime - currentTime;

  // Clear any existing timeout
  clearTimeout(expiredTimer);

  // Set a new timeout to handle token expiration
  expiredTimer = setTimeout(() => {
    // call getSession to refresh token
    getSession();
  }, timeLeft);
};

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    const decoded = decodeJWT(accessToken);
    tokenExpired(decoded?.exp);
    // Attach token to axios instance
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // Attach token to appolo client ctx
    updateToken(accessToken);
  }
};
