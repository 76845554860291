// mui
import { useTranslation } from 'react-i18next';
import { LabelOutlined } from '@mui/icons-material';
import { Link, Stack, Typography, alpha } from '@mui/material';
import { grey } from '@mui/material/colors';
//types
import { NavLinkItem } from './types';

interface SubLinksProps {
  subs: NavLinkItem[];
}

export const SubLinks = ({ subs }: SubLinksProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'common' });

  return (
    <Stack
      spacing={1}
      sx={{
        background: grey[50],
        zIndex: 100000,
        position: 'absolute',
        left: 0,
        width: '100%',
        minWidth: '200px',
        bottom: '55px',
      }}
    >
      {subs.map(sub => (
        <Stack
          component={Link}
          href={sub.path}
          target={sub?.isExternalLink ? '_blank' : '_self'}
          key={t(sub.title)}
          spacing={1}
          direction="row"
          sx={{
            textDecoration: 'none',
            textTransform: 'none',
            padding: '6px 14px 6px 20px',
            '&:hover': {
              background: theme => alpha(theme.palette.common.black, 0.08),
            },
            color: theme => theme.palette.secondary.main,
          }}
        >
          <LabelOutlined fontSize="medium" />
          <Typography
            sx={{ textDecoration: 'none', textTransform: 'none', letterSpacing: '0.4px' }}
            color="secondary"
            variant="subtitle2"
          >
            {t(sub.title)}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
