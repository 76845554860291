// utils
import { Routes } from '@/utils/urls';
import { NavItem } from './types';

export const NAV_WIDTH = 275;
export const DYNAMIC_NAV_WIDTH = 300;
export const NAV_MINI_WIDTH = 115;
export const LIST_ITEM_ICON_OFFSET = 13;

const { Home, Authority, Discloser, Account, Organisation, Questionnaire, Knowledge } = Routes;

export const navConfig: NavItem[] = [
  // home
  {
    label: 'nav.dashboard',
    path: Home,
  },
  // authority dashboard
  {
    label: 'nav.authority.label',
    path: Authority.Root,
    userType: 'authority',
    items: [
      { label: 'nav.authority.manage', path: Authority.Manage },
      { label: 'nav.authority.track', path: Authority.Root },
    ],
  },
  // disclosure dashboard
  {
    label: 'nav.discloser.label',
    path: Discloser.Root,
    userType: 'discloser',
    items: [
      {
        label: 'nav.discloser.request',
        path: Discloser.Disclose,
      },
      { label: 'nav.discloser.manage', path: Discloser.Manage },
      {
        label: 'nav.discloser.previous',
        path: Discloser.History,
      },
    ],
  },
  // account
  {
    label: 'nav.account.label',
    path: Account,
  },
  // organization
  {
    label: 'nav.organisation.label',
    path: Organisation.Root,
    items: [
      { label: 'nav.organisation.details', path: Organisation.Details },
      { label: 'nav.organisation.users', path: Organisation.Users },
    ],
  },
  // questionnaire
  {
    label: 'nav.questionnaire',
    path: Questionnaire.Root,
  },
  // knowledge
  {
    label: 'nav.knowledge',
    path: Knowledge,
  },
];
