import { useRouter } from 'next/router';

type ReturnType = {
  active: boolean;
};

export default function useActiveRoute(path: string, deep = true): ReturnType {
  const { pathname, asPath } = useRouter();

  /**
   * asPath - The path as shown in the browser including the search params and respecting the trailingSlash configuration.
   * basePath and locale are not included
   */
  const active = pathname === path || asPath === path;

  /**
   * True - Sub route is active
   */
  const deepActive = pathname.includes(path) || asPath.includes(path);

  return {
    active: deep ? deepActive : active,
  };
}
