// next
import Image from 'next/image';
// mui
import { styled } from '@mui/material';

const StyledRoot = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,

  '.logo-animation': {
    animation: 'pulse 2s infinite',
  },

  '@keyframes pulse': {
    '0% ': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.5)',
    },
    '100% ': {
      transform: 'scale(1)',
    },
  },
}));

export default function LoadingScreen() {
  return (
    <StyledRoot data-testid="loading-screen">
      <Image
        className="logo-animation"
        src="/images/logo.svg"
        alt="CDP"
        width={100}
        height={100}
      />
    </StyledRoot>
  );
}
