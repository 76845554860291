import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { FunctionComponent } from 'react';
import { HeaderView } from '@/components/dynamic-nav/header/HeaderView';
import { Role } from '@/components/dynamic-nav/types';
import Logo from '@/components/nav/Logo';
import { GetMyOrganisationDetailsQuery } from '@/lib/discloser/__generated__/graphql';
import { useNavContext } from '@/providers/NavProvider/navContextProvider';
import { ApolloError } from '@apollo/client';
import { Button, Skeleton, Stack, Typography, alpha } from '@mui/material';

interface Props {
  role: Role;
  myOrgData: GetMyOrganisationDetailsQuery | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
}

export const HeaderContainer: FunctionComponent<Props> = ({ role, myOrgData, loading, error, refetch }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common.nav.header' });
  const { t: t2 } = useTranslation('dynamic', { keyPrefix: 'dynamic.authorityTypes' });

  const { data: session } = useSession();
  const { isOpen } = useNavContext();

  if (loading)
    return (
      <Skeleton
        height={100}
        data-testid="header-skeleton"
      />
    );

  if (error) {
    return (
      <Stack
        sx={theme => ({
          backgroundColor: alpha(theme.palette.secondary.main, 0.04),
          width: '100%',
          padding: 1,
        })}
      >
        <Typography
          noWrap
          marginBottom={1}
        >
          {t('error')}
        </Typography>
        <Button
          onClick={refetch}
          color="primary"
          variant="contained"
        >
          {t('reload')}
        </Button>
      </Stack>
    );
  }

  // This will return null when the user is a CDP admin, thus
  // we will just render the Logo as per design spec. Any errors
  // will be surfaced in the check above.
  if (!myOrgData?.getMyOrganisationDetails || role === Role.CDP_ADMIN) return <Logo isOpened={isOpen} />;

  return (
    <HeaderView
      jobTitle={session?.user.jobTitle}
      orgName={myOrgData.getMyOrganisationDetails.orgName}
      imageUrl={myOrgData.getMyOrganisationDetails.logoUrl}
      authorityTypes={myOrgData.getMyOrganisationDetails?.organisationAuthorityTypes?.map(authorityType => ({
        id: authorityType.authorityType.authorityTypeId,
        name: t2(authorityType.authorityType.name),
      }))}
    />
  );
};
